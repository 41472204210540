<template>
  <div class="gatewayParam">
    <!-- 参数 -->
    <Head :name="'插卡取电面板'" :add="'/pc/ProCard'" :adds="'/pc/ProCardParam'"  :color="false" />
    <!-- 相关参数 -->
    <div class="paramBox">
      <div class="param">
        <div class="paramTit">相关参数</div>
         <div class="paramImg">
          <img src="../../../assets/product/cardParam.png" alt class="img" />
          <div class="imgName">插卡取电面板</div>
        </div>
        <div class="pas">
          <div class="pasLeft">
            <div class="li">
              <div class="pasName">产品型号:</div>
              <div class="pasCont">LSS02A</div>
            </div>
            <div class="li">
              <div class="pasName">产品尺寸:</div>
              <div class="pasCont">符合86盒标准：86×86mm</div>
            </div>
            <div class="li">
              <div class="pasName">额定输入:</div>
              <div class="pasCont">AC200V~250V/50Hz</div>
            </div>
          </div>
          <div class="pasRight">
            <div class="li">
              <div class="pasName">额定输出:</div>
              <div class="pasCont">220V/30A</div>
            </div>
            <div class="li">
              <div class="pasName">工作温度:</div>
              <div class="pasCont">0°C-40°C</div>
            </div>
            <div class="li">
              <div class="pasName">工作湿度:</div>
              <div class="pasCont">0%-95%RH，无冷凝</div>
            </div>
          </div>
        </div>
        <div class="text">
          <div
            class="tx"
          >插卡取电面板可控制客房内除UPS电源以外的所有电源的通断。当客人把房卡插入面板后，面板会读取卡内的客房信息并上报主机进行有效性确认。只有授权有效的房卡才能使插卡取电面板开启房间内的电源。当客人出门拔卡后，插卡取电面板会继续维持房间电源，直至30s延时结束后断电。</div>
        </div>
        <div class="star">* 以上所有数据来自BOT实验室，依据测量方式不同,实际结果可能略有差异。</div>
      </div>
    </div>
  </div>
</template>
<script>
import Head from "../../../components/Head.vue";
export default {
  components: {
    Head
  },
  data() {
    return {};
  }
};
</script>
<style lang="less" scoped>
// 相关参数
.paramBox {
  width: 100%;
  background-color: #fff;
  .param {
    width: 12rem;
    margin: 0 auto;
    padding: 1.2rem 0px;
    .paramTit {
      font-size: 0.48rem;
      line-height: 0.48rem;
      font-weight: bold;
      color: #ff9000;
    }
     .paramImg {
      margin-top: 0.65rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      .img {
        width: 3rem;
        height: 3rem;
      }
      .imgName {
        margin-top: 0.59rem;
        font-size: 0.36rem;
        line-height: 0.36rem;
        font-weight: 400;
        color: #333333;
      }
    }
    .pas {
      margin-top: 0.84rem;
      display: flex;
      text-align: left;
      justify-content: space-between;
      div {
        display: flex;
        flex-direction: column;
        .li {
          display: flex;
          flex-direction: row;
          margin-bottom: 0.38rem;
          font-size: 0.24rem;
          line-height: 0.24rem;
          font-weight: 400;
          color: #333333;
          &:last-of-type {
            margin-bottom: 0px;
          }
          .pasName {
            margin-right: 1.21rem;
          }
        }
      }
    }
    .text {
      margin-top: 0.43rem;
      border-top: 1px solid #333;
      padding-top: 0.4rem;
      text-align: justify;
      font-size: 0.24rem;
      font-weight: 400;
      color: #333333;
      line-height: 0.4rem;
    }
    .star {
      margin-top: 0.8rem;
      text-align: left;
      font-size: 0.1rem;
      line-height: 0.1rem;
      font-weight: 300;
      color: #333333;
    }
  }
}
</style>
